import { useTextField } from '@react-aria/textfield';
import cn from 'clsx';
import * as React from 'react';
import { forwardRef, memo, RefObject, useRef } from 'react';

import { FontSizeVals, HeightVals, IMarginProps, IntentVals, SpaceVals } from '../../enhancers';
import { splitBoxProps } from '../../utils';
import { Box } from '../Box';
import { BoxOwnProps, IBoxHTMLAttributes } from '../Box/types';
import { Flex } from '../Flex';
import { Icon, IIconProps, isIconProp } from '../Icon';
import { AppearanceVals, variants } from './variants';

export interface IInputProps
  extends IMarginProps,
    BoxOwnProps<React.ElementType<'input'>>,
    Omit<
      React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
      'width' | 'height' | 'color' | 'size'
    > {
  appearance?: AppearanceVals;
  intent?: IntentVals;
  size?: 'sm' | 'md' | 'lg';
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  icon?: IIconProps['icon'] | React.ReactElement;
}

const sizes: Partial<Record<HeightVals, { px: SpaceVals }>> = {
  lg: { px: 3 },
  md: { px: 2.5 },
  sm: { px: 1.5 },
};

const fontSizes: Partial<Record<HeightVals, FontSizeVals>> = {
  lg: 'lg',
  md: 'base',
  sm: 'base',
};

export const Input = memo(
  forwardRef<HTMLInputElement, IInputProps>(function Input(
    {
      appearance = 'default',
      intent = 'default',
      size = 'md',
      readOnly,
      disabled,
      className,
      icon,
      required,
      value,
      defaultValue,
      onChange,
      ...props
    },
    ref: RefObject<HTMLInputElement>,
  ) {
    const { matchedProps, remainingProps } = splitBoxProps(props);

    const fallbackRef = useRef();
    const inputRef = ref || fallbackRef;
    const {
      inputProps: { color, ...inputProps },
    } = useTextField(
      {
        ...remainingProps,
        value: typeof value !== 'undefined' ? String(value) : value,
        defaultValue: typeof defaultValue !== 'undefined' ? String(defaultValue) : defaultValue,
        onInput: onChange,
        isDisabled: disabled,
        isReadOnly: readOnly,
        isRequired: required,
      },
      inputRef,
    );

    const stateProps: Partial<BoxOwnProps> = {
      ...variants.default.default,
      ...variants.default[intent],
      ...variants[appearance].default,
      ...variants[appearance][intent],
    };

    let disabledProps: Partial<BoxOwnProps> = {};
    let disabledInputProps: Partial<BoxOwnProps> = {};
    if (disabled) {
      disabledProps = {
        bg: 'canvas-100',
        color: 'muted',
      };
      disabledInputProps = {
        cursor: 'not-allowed',
      };
    }

    let readOnlyProps: Partial<IBoxHTMLAttributes & BoxOwnProps> = {};
    if (readOnly) {
      readOnlyProps.tabIndex = -1;

      if (appearance === 'minimal') {
        readOnlyProps.borderColor = 'transparent';
      }
    }

    return (
      <Box className={cn('sl-input', className)} pos="relative" {...matchedProps} {...disabledProps}>
        {icon ? <InputIcon icon={icon} /> : null}

        <Box
          as="input"
          ref={inputRef}
          pl={icon ? 8 : sizes[size].px}
          pr={sizes[size].px}
          fontSize={fontSizes[size]}
          rounded
          h={size}
          border
          w="full"
          disabled={disabled}
          readOnly={readOnly}
          pos="relative"
          zIndex={10}
          {...disabledInputProps}
          {...remainingProps}
          {...stateProps}
          {...readOnlyProps}
          {...inputProps}
        />
      </Box>
    );
  }),
);

const InputIcon = ({ icon }: { icon: IInputProps['icon']; size?: IInputProps['size'] }) => {
  let elem = icon;
  if (isIconProp(icon)) {
    elem = <Icon icon={icon} size="sm" fixedWidth />;
  }

  return (
    <Flex pos="absolute" align="center" zIndex={0} style={{ top: 0, bottom: 0, left: 0, lineHeight: 0 }} pl={2}>
      {elem}
    </Flex>
  );
};
