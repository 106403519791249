import cn from 'clsx';
import * as React from 'react';
import { memo } from 'react';

import { BackgroundColorVals, IntentVals } from '../../enhancers';
import { useTheme } from '../../hooks/use-theme';
import { Box } from '../Box';
import { BoxProps } from '../Box/types';
import { Heading } from '../Heading';
import { Icon, IIconProps, isIconProp } from '../Icon';
import { HStack, VStack } from '../Stack';
import { iconVariants, variants } from './variants';

export type CalloutProps = BoxProps<React.ElementType> & {
  /** Name or Icon props of a Font Awesome Icon to render*/
  icon?: IIconProps['icon'] | React.ReactElement;

  /**
   * Intent style for the callout. Seting will change callout colors and icon.
   * @default default
   */
  intent?: IntentVals | 'info';

  /**
   * appearance style for the callout. Setting to outline will change callout backgound from solid to transparent.
   * @default default
   */
  appearance?: 'default' | 'outline';

  /**
   * Displayed as the title of the callout
   */
  heading?: string;

  /**
   * Actions to be displayed on the right side of the callout. Useful if the callout needs to display a button or select.
   */
  actions?: React.ReactElement;
};

export const Callout = memo(function Callout({
  intent = 'default',
  appearance = 'default',
  heading,
  className,
  icon,
  actions,
  children,
  ...props
}: CalloutProps) {
  const color = intent === 'default' || intent === 'info' ? 'primary' : intent;
  const { themeValue: value } = useTheme(`colors.${color}`);

  const stateProps: Partial<CalloutProps> = {
    ...variants[appearance].default,
    ...variants[appearance][intent],
  };

  return (
    <Box
      className={cn('sl-callout', className)}
      rounded="xl"
      w="full"
      pb={appearance === 'default' ? undefined : 1}
      bg={appearance === 'default' ? undefined : (stateProps.borderColor as BackgroundColorVals)}
      role="alert"
      {...props}
    >
      <HStack
        spacing={3}
        pl={4}
        pr={actions ? 4 : 10}
        py={4}
        alignItems="start"
        rounded="xl"
        bg={appearance === 'default' ? undefined : 'canvas-pure'}
        {...stateProps}
      >
        {icon !== null ? (
          <Box mt="px">
            <HeadingIcon icon={icon} intent={intent} intentColorValue={value} />
          </Box>
        ) : null}

        <VStack flex={1}>
          {heading ? (
            <Heading size={4} mb={1.5}>
              {heading}
            </Heading>
          ) : null}

          {children}
        </VStack>

        {actions}
      </HStack>
    </Box>
  );
});

const HeadingIcon = ({
  icon,
  intent,
  intentColorValue,
}: Pick<CalloutProps, 'icon' | 'intent'> & { intentColorValue: string }) => {
  let elem = null;

  if (intent && !icon) {
    const intentIcon = iconVariants.intent[intent];
    if (intentIcon) {
      elem = <Icon icon={intentIcon} style={{ color: intentColorValue }} size="lg" />;
    }
  } else if (icon && isIconProp(icon)) {
    elem = <Icon icon={icon} style={{ color: intentColorValue }} size="lg" />;
  }

  return elem ? <Box data-testid="icon">{elem}</Box> : null;
};
