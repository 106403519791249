import cn from 'clsx';
import memoize from 'nano-memoize';
import equals from 'react-fast-compare';

import { SemanticColorVals } from './color';
import { OpacityVals } from './interactivity';
import { computePseudoClasses, Pseudo } from './pseudo';
import { EnhancerFn } from './types';

export type OutlineVals = 'none';

/*
 * Border
 */

export type BorderColorVals = 'body' | 'light' | 'dark' | 'button' | 'input' | 'transparent' | SemanticColorVals;

// true allows us to add shorthand `border` prop to react component to get a basic border, with no val
export type BorderWidthVals = true | 0 | 2 | 4 | 8;

export type RoundedVals =
  | true
  | 'none'
  | 'sm'
  | 'lg'
  | 'xl'
  | 'full'
  | 'b'
  | 't'
  | 'l'
  | 'r'
  | 'tl'
  | 'tr'
  | 'bl'
  | 'br'
  | 'b-none'
  | 't-none'
  | 'l-none'
  | 'r-none'
  | 'tl-none'
  | 'tr-none'
  | 'bl-none'
  | 'br-none'
  | 'b-sm'
  | 't-sm'
  | 'l-sm'
  | 'r-sm'
  | 'tl-sm'
  | 'tr-sm'
  | 'bl-sm'
  | 'br-sm'
  | 'b-lg'
  | 't-lg'
  | 'l-lg'
  | 'r-lg'
  | 'tl-lg'
  | 'tr-lg'
  | 'bl-lg'
  | 'br-lg'
  | 'b-xl'
  | 't-xl'
  | 'l-xl'
  | 'r-xl'
  | 'tl-xl'
  | 'tr-xl'
  | 'bl-xl'
  | 'br-xl'
  | 'b-full'
  | 't-full'
  | 'l-full'
  | 'r-full'
  | 'tl-full'
  | 'tr-full'
  | 'bl-full'
  | 'br-full';

export type BorderProps = {
  outline?: OutlineVals;
  borderColor?: BorderColorVals | Pseudo<BorderColorVals, 'hover' | 'focus' | 'active'>;
} & BorderWidthProps &
  BorderRoundedProps;

export type BorderWidthProps = {
  border?: BorderWidthVals;
  borderT?: BorderWidthVals;
  borderR?: BorderWidthVals;
  borderL?: BorderWidthVals;
  borderB?: BorderWidthVals;
};

export type BorderRoundedProps = {
  rounded?: RoundedVals;
  roundedT?: RoundedVals;
  roundedR?: RoundedVals;
  roundedL?: RoundedVals;
  roundedB?: RoundedVals;
  roundedTL?: RoundedVals;
  roundedTR?: RoundedVals;
  roundedBL?: RoundedVals;
  roundedBR?: RoundedVals;
};

export const borderPropNames: Array<keyof BorderProps> = [
  'borderColor',
  'border',
  'borderT',
  'borderR',
  'borderL',
  'borderB',
  'rounded',
  'roundedT',
  'roundedR',
  'roundedL',
  'roundedB',
  'roundedTL',
  'roundedTR',
  'roundedBL',
  'roundedBR',
];

export const borderProps: EnhancerFn<BorderProps> = (props: BorderProps) => {
  const {
    outline,
    rounded,
    roundedB,
    roundedT,
    roundedR,
    roundedL,
    roundedBL,
    roundedBR,
    roundedTL,
    roundedTR,
    borderColor,
    border,
    borderT,
    borderR,
    borderL,
    borderB,
    ...rest
  } = props;

  return {
    props: rest,
    className: _borderProps(
      outline,
      rounded,
      roundedB,
      roundedT,
      roundedR,
      roundedL,
      roundedBL,
      roundedBR,
      roundedTL,
      roundedTR,
      borderColor,
      border,
      borderT,
      borderR,
      borderL,
      borderB,
    ),
  };
};

const _borderProps = memoize(
  (
    outline: BorderProps['outline'],
    rounded: BorderProps['rounded'],
    roundedB: BorderProps['roundedB'],
    roundedT: BorderProps['roundedT'],
    roundedR: BorderProps['roundedR'],
    roundedL: BorderProps['roundedL'],
    roundedBL: BorderProps['roundedBL'],
    roundedBR: BorderProps['roundedBR'],
    roundedTL: BorderProps['roundedTL'],
    roundedTR: BorderProps['roundedTR'],
    borderColor: BorderProps['borderColor'],
    border: BorderProps['border'],
    borderT: BorderProps['borderT'],
    borderR: BorderProps['borderR'],
    borderL: BorderProps['borderL'],
    borderB: BorderProps['borderB'],
  ) => {
    return cn(
      {
        [`sl-outline-${outline}`]: outline !== void 0,
        [`sl-rounded${rounded === true ? '' : '-' + rounded}`]: rounded,
        [`sl-rounded-b${roundedB === true ? '' : '-' + roundedB}`]: roundedB,
        [`sl-rounded-t${roundedT === true ? '' : '-' + roundedT}`]: roundedT,
        [`sl-rounded-r${roundedR === true ? '' : '-' + roundedR}`]: roundedR,
        [`sl-rounded-l${roundedL === true ? '' : '-' + roundedL}`]: roundedL,
        [`sl-rounded-bl${roundedBL === true ? '' : '-' + roundedBL}`]: roundedBL,
        [`sl-rounded-br${roundedBR === true ? '' : '-' + roundedBR}`]: roundedBR,
        [`sl-rounded-tl${roundedTL === true ? '' : '-' + roundedTL}`]: roundedTL,
        [`sl-rounded-tr${roundedTR === true ? '' : '-' + roundedTR}`]: roundedTR,
      },
      computePseudoClasses('border', borderColor),
      computePseudoClasses('border', border),
      computePseudoClasses('border-t', borderT),
      computePseudoClasses('border-r', borderR),
      computePseudoClasses('border-l', borderL),
      computePseudoClasses('border-b', borderB),
    );
  },
  { maxAge: Infinity, equals },
);

/*
 * Ring
 */

export type RingWidthVals = true | false;
export type RingColorVals = 'primary' | 'success' | 'warning' | 'danger';
export type RingOpacityVals = OpacityVals;

export interface IRingProps {
  ring?: RingWidthVals | Pseudo<RingWidthVals, 'focus'>;
  ringColor?: RingColorVals | Pseudo<RingColorVals, 'focus'>;
  ringOpacity?: RingOpacityVals | Pseudo<RingOpacityVals, 'focus'>;
}

export const ringPropNames: Array<keyof IRingProps> = ['ring', 'ringColor'];

export const ringProps: EnhancerFn<IRingProps> = (props: IRingProps) => {
  const { ring, ringColor, ringOpacity, ...rest } = props;

  return {
    props: rest,
    className: _ringProps(ring, ringColor, ringOpacity),
  };
};

const _ringProps = memoize(
  (ring: IRingProps['ring'], ringColor: IRingProps['ringColor'], ringOpacity: IRingProps['ringOpacity']) => {
    return cn(
      computePseudoClasses('ring', ring),
      computePseudoClasses('ring', ringColor),
      computePseudoClasses('ring-opacity', ringOpacity),
    );
  },
  { maxAge: Infinity, equals },
);
