import { FocusRing } from '@react-aria/focus';
import { usePress } from '@react-aria/interactions';
import { FocusableRefValue } from '@react-types/shared';
import * as React from 'react';
import { forwardRef, RefObject } from 'react';

import { Box, BoxOwnProps } from '../Box';
import { Flex } from '../Flex';
import { Icon } from '../Icon';
import { Button, ButtonOwnProps, ButtonProps } from './Button';
import { FieldButtonAppearanceVals, fieldButtonVariants } from './variants';

export type FieldButtonProps = Omit<ButtonProps, 'appearance'> & {
  placeholder?: string;
  appearance?: FieldButtonAppearanceVals;
  onClear?: () => void;
};

function FieldButton(
  {
    children,
    placeholder,
    onClear,
    appearance = 'minimal',
    intent,
    active,
    loading,
    disabled,
    ...props
  }: FieldButtonProps,
  ref: RefObject<FocusableRefValue<HTMLElement>>,
) {
  const showClearButton = !!(onClear && children);

  const stateProps: Partial<BoxOwnProps> = {
    ...fieldButtonVariants.minimal.default,
    ...fieldButtonVariants.minimal[intent],
    ...fieldButtonVariants[appearance]?.default,
    ...fieldButtonVariants[appearance]?.[intent],
  };

  let disabledProps: Partial<BoxOwnProps> | null = null;
  if (disabled) {
    disabledProps = {
      cursor: 'not-allowed',
    };
    if (appearance === 'outlined') {
      disabledProps.bg = 'canvas-100';
    }
  }

  /**
   * If active, remove other state effects
   */
  if (active) {
    for (const i in stateProps) {
      const prop = stateProps[i];
      if (prop && typeof prop === 'object') {
        if (prop.hasOwnProperty('active')) {
          stateProps[i] = prop.active;
        } else if (prop.hasOwnProperty('hover')) {
          // remove props immutably
          const { hover, ...newProps } = stateProps[i];
          stateProps[i] = newProps;
        }
      }
    }
  }

  /**
   * If in loading or disabled states, remove other ui effects like hover
   */
  if (loading || disabled) {
    for (const i in stateProps) {
      const prop = stateProps[i];
      if (prop && typeof prop === 'object') {
        // remove props immutably
        const { active, hover, ...newProps } = stateProps[i];
        stateProps[i] = newProps;
      }
    }
  }

  return (
    <Box pos="relative" {...disabledProps}>
      <Button
        iconRight={
          <Box pt={0.5} pr={0.5}>
            <Icon icon="chevron-down" size="xs" />
          </Box>
        }
        noFocusRing
        active={active}
        loading={loading}
        disabled={disabled}
        {...props}
        {...stateProps}
        ref={ref}
      >
        <Flex flex={1} justifyItems="start" alignItems="center">
          <Box pr={1} color={!children ? 'light' : undefined}>
            {children || placeholder || ''}
          </Box>

          {showClearButton && <div style={{ width: props.size === 'sm' ? 24 : 28 }} />}
        </Flex>
      </Button>

      {showClearButton && <FieldButtonClear triggerRef={ref} onClear={onClear} size={props.size} />}
    </Box>
  );
}

const _FieldButton = forwardRef(FieldButton);
export { _FieldButton as FieldButton };

const FieldButtonClear = ({
  triggerRef,
  size,
  onClear,
}: {
  triggerRef: RefObject<FocusableRefValue<HTMLElement>>;
  size?: ButtonOwnProps['size'];
  onClear: FieldButtonProps['onClear'];
}) => {
  const { pressProps } = usePress({
    onPress: () => {
      onClear();

      // move focus back to the field button
      triggerRef?.current?.focus();
    },
  });

  return (
    <FocusRing focusRingClass="sl-focus-ring">
      <Flex
        as="button"
        {...pressProps}
        borderR
        borderColor="input"
        fontSize="base"
        px={size === 'sm' ? 1.5 : 2}
        alignItems="center"
        color={{ hover: 'danger', focus: 'danger' }}
        pos="absolute"
        cursor="pointer"
        style={{ top: '50%', right: size === 'sm' ? 20 : 24, height: 13, marginTop: -6, lineHeight: 0 }}
        aria-label="Clear selected value"
      >
        <Icon icon="times" size="xs" />
      </Flex>
    </FocusRing>
  );
};
