import { BoxOwnProps } from '../Box/types';

export type AppearanceVals = 'minimal' | 'line' | 'pill';
export type OrientationVals = 'horizontal' | 'vertical';
export type DensityVals = 'compact' | 'regular';
export type AlignVals = 'center' | 'end' | 'start';
export type DirectionVals = 'ltr' | 'rtl';

export const variants: Record<
  AppearanceVals,
  Record<OrientationVals, Record<DensityVals, Record<'tabList' | 'tab', Partial<BoxOwnProps>>>>
> = {
  minimal: {
    horizontal: {
      regular: {
        tabList: {},
        tab: {
          py: 2,
          px: 3,
        },
      },
      compact: {
        tabList: {},
        tab: {
          py: 1.5,
          px: 2,
        },
      },
    },
    vertical: {
      regular: {
        tabList: {},
        tab: {
          py: 1.5,
          px: 3,
        },
      },
      compact: {
        tabList: {},
        tab: {
          py: 1.5,
          px: 3,
        },
      },
    },
  },
  line: {
    horizontal: {
      regular: {
        tabList: {
          borderB: 2,
        },
        tab: {
          mb: -0.5,
          borderB: 2,
          py: 2,
          px: 4,
        },
      },
      compact: {
        tabList: {
          borderB: 2,
        },
        tab: {
          mb: -0.5,
          borderB: 2,
          py: 1.5,
          px: 2,
        },
      },
    },
    vertical: {
      regular: {
        tabList: {
          borderR: 2,
        },
        tab: {
          mr: -0.5,
          borderR: 2,
          py: 2,
          px: 4,
        },
      },
      compact: {
        tabList: {
          borderR: 2,
        },
        tab: {
          mr: -0.5,
          borderR: 2,
          py: 1.5,
          px: 3,
        },
      },
    },
  },
  pill: {
    horizontal: {
      regular: {
        tabList: {},
        tab: {
          py: 2,
          px: 3,
          rounded: 'lg',
        },
      },
      compact: {
        tabList: {},
        tab: {
          py: 1,
          px: 2,
          rounded: 'lg',
        },
      },
    },
    vertical: {
      regular: {
        tabList: {},
        tab: {
          py: 1.5,
          px: 3,
          rounded: 'lg',
        },
      },
      compact: {
        tabList: {},
        tab: {
          py: 1,
          px: 2,
          rounded: 'lg',
        },
      },
    },
  },
};
