import cn from 'clsx';
import * as React from 'react';
import { forwardRef, HTMLAttributes, LegacyRef, ReactNode } from 'react';

import { Cursor } from '../../enhancers';
import { useInteractionModality } from '../../hooks';
import { Box, BoxOwnProps } from '../Box';
import { Flex } from '../Flex';
import { Icon } from '../Icon';
import { Link } from '../Link';
import { MenuBaseItem } from './types';

export type MenuItemRowProps = {
  title: ReactNode;
  id?: string;
  isFocused?: boolean;
  isActive?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
  hasSubmenu?: boolean;
  size?: 'lg' | 'default';
  cursor?: Cursor;
} & Pick<MenuBaseItem, 'isIndented' | 'icon' | 'iconColor' | 'meta' | 'href' | 'description'>;

export const MENU_ITEM_IDENT_WIDTHS: Record<MenuItemRowProps['size'], string> = {
  default: '28px',
  lg: '40px',
};

export const Internal_MenuItemRow = forwardRef(function MenuItem(
  {
    title,
    isFocused,
    isActive,
    isDisabled,
    isSelected,
    hasSubmenu,
    isIndented,
    icon,
    iconColor,
    meta,
    href,
    description,
    size = 'default',
    cursor = true,
    ...restProps
  }: MenuItemRowProps,
  ref: LegacyRef<undefined>,
) {
  const isCheckableItem = typeof isSelected !== 'undefined';
  const shouldIndent = isIndented || isCheckableItem || typeof icon !== 'undefined';
  const modality = useInteractionModality();
  const pointerInteraction = modality === 'pointer';
  const isLink = !!href;
  const isExternalLink = isLink && !href.startsWith('/');

  const rowChildren = (
    <>
      {shouldIndent && (
        <Box
          style={{
            width: MENU_ITEM_IDENT_WIDTHS[size],
            fontSize: isCheckableItem ? (size === 'lg' ? 12 : 10) : undefined,
          }}
          flexShrink={0}
          textAlign="center"
          data-testid="icon"
        >
          {(isSelected || icon) && (
            <Icon className="sl-menu-item__icon" icon={isSelected ? 'check' : icon} style={{ color: iconColor }} />
          )}
        </Box>
      )}

      <Box flex={1} w="full" pr={8} className="sl-menu-item__title-wrapper">
        <Box lineHeight={size === 'lg' ? 'relaxed' : undefined} textOverflow="truncate">
          {title}
        </Box>
        {description && (
          <Box
            className="sl-menu-item__description"
            lineHeight={size === 'lg' ? 'relaxed' : undefined}
            color="light"
            fontSize={size === 'lg' ? 'base' : 'sm'}
            whitespace="normal"
          >
            {description}
          </Box>
        )}
      </Box>

      {isExternalLink ? (
        <Box className="sl-menu-item__link-icon">
          <Icon icon="external-link-alt" size="sm" />
        </Box>
      ) : null}

      {(meta || meta === 0) && <Box className="sl-menu-item__meta">{meta}</Box>}

      {hasSubmenu && (
        <Box>
          <Icon icon={['fas', 'caret-right']} />
        </Box>
      )}
    </>
  );

  const rowProps: BoxOwnProps = {
    ...restProps,
    pl: size === 'lg' ? (shouldIndent ? 2 : 5) : shouldIndent ? 1 : 3,
    pr: size === 'lg' ? 4 : 3,
    pt: size === 'lg' ? 2 : 1,
    pb: size === 'lg' ? 2 : 1,
    alignItems: 'center',
    whitespace: 'nowrap',
    fontSize: size === 'lg' ? 'lg' : 'base',
    opacity: isDisabled ? 50 : undefined,
    className: cn('sl-menu-item', {
      'sl-menu-item--has-submenu': hasSubmenu,
      'sl-menu-item--disabled': isDisabled,
      'sl-menu-item--submenu-active': !isFocused && isActive,
      'sl-menu-item--focused': (!pointerInteraction || hasSubmenu) && isFocused,
    }),
  };

  if (isLink && !isDisabled) {
    return (
      <Link
        ref={ref}
        href={href}
        target={href && !href.startsWith('/') ? '_blank' : undefined}
        display="flex"
        cursor={cursor}
        {...rowProps}
      >
        {rowChildren}
      </Link>
    );
  }

  return (
    <Flex ref={ref} cursor={isDisabled ? 'not-allowed' : cursor} {...rowProps}>
      {rowChildren}
    </Flex>
  );
});
