import deepmerge from 'deepmerge';
import _get from 'lodash.get';
import { getLuminance, readableColor } from 'polished';

import { getContrastColor, HslColor2, parseHsl, stringifyHsl } from './color-manipulation';

export type ThemeMode = 'light' | 'dark' | 'system';

type Color = string;
type MultiplierValues = { s: number; l: number };

export interface ITheme {
  colors: {
    background: Color; // Body background color, the background hue is used to derive a few other colors
    text?: Color; // Body foreground color, currently being calculated from the background automatically
    primary: Color; // Primary brand color for links, buttons, etc.
    success: Color;
    warning: Color;
    danger: Color;

    // possible additions
    // active?: Color; // Used to indicate active state, for example in sidebars, defaults to background hue
    // secondary: Color; // 	A secondary brand color for alternative styling
    // highlight: Color; // A background color for highlighting text
  };
}

export interface ICustomTheme {
  colors: Partial<ITheme['colors']>;
}

export type ColorValues = { light?: boolean } & Record<keyof Omit<ITheme['colors'], 'modes'>, HslColor2>;

export const defaultTheme: ITheme = {
  colors: {
    background: 'hsl(218, 40%, 100%)',
    // text: 'hsl(214, 15%, 9%)', // defaults to compute based on background
    primary: 'hsl(202, 100%, 55%)',
    success: 'hsl(156, 95%, 37%)',
    warning: 'hsl(20, 90%, 56%)',
    danger: 'hsl(0, 84%, 63%)',
  },
};

export const getCssVariable = (name: string, element?: HTMLElement) => {
  if (typeof document === void 0) return null;

  return getComputedStyle(element || document.body).getPropertyValue(name);
};

// so that lines don't wrap so much, hard to read
const r = Math.round;

export const prefersDarkMode = () =>
  typeof window !== 'undefined' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');

/**
 * Handles figuring out what 'system' mode resolves to if user has that picked. Always returns light | dark.
 */
export const getResolvedThemeMode = (userMode: ThemeMode) => {
  if (userMode === 'system') {
    return prefersDarkMode() ? 'dark' : 'light';
  }

  return userMode;
};

export const computeTheme = (customTheme: ICustomTheme, $mode: ThemeMode = 'system') => {
  const mode = getResolvedThemeMode($mode);
  const theme = deepmerge(defaultTheme, customTheme);

  // when the user has a preference for dark screen, reduce the saturation and lightness
  const mult = {
    s: mode === 'dark' ? 0.8 : 1,
    l: mode === 'dark' ? 0.65 : 1,
  };

  const backgroundHsl = parseHsl(theme.colors.background);
  if (mode === 'dark' && backgroundHsl.l > 20) {
    backgroundHsl.s = r(Math.min(60, backgroundHsl.s * mult.s));
    backgroundHsl.l = 8;
  }

  // main colors
  const cv: ColorValues = {
    light: backgroundHsl.l >= 50,
    background: backgroundHsl,
    text: theme.colors.text
      ? parseHsl(theme.colors.text)
      : parseHsl(
          readableColor(
            stringifyHsl(backgroundHsl),
            stringifyHsl({ h: backgroundHsl.h, s: backgroundHsl.s, l: Math.max(95, backgroundHsl.l) }),
            stringifyHsl({ h: backgroundHsl.h, s: backgroundHsl.s, l: Math.min(5, backgroundHsl.l) }),
            true,
          ),
        ),
    primary: parseHsl(theme.colors.primary),
    success: parseHsl(theme.colors.success),
    warning: parseHsl(theme.colors.warning),
    danger: parseHsl(theme.colors.danger),
  };

  /**
   * Inverted colors
   */

  const invertedBackgroundHsl = invertColorValue(cv.background, cv.light);

  /**
   * If user is asking for dark mode, then regular background will always be dark, and we make inverted background
   * just a little lighter than regular background, to raise it (opposite of how "raise" works on light backgrounds, where you darken to give a raise effect)
   */
  if (mode === 'dark') {
    invertedBackgroundHsl.l = cv.background.l + 5;
  }

  /** Is the inverted bg a light color? */
  const icvLight = invertedBackgroundHsl.l >= 50;

  const icv: ColorValues = {
    light: icvLight,
    background: invertedBackgroundHsl,
    text: {
      h: cv.text.h,
      s: r(cv.text.s / 2),
      l: icvLight ? 5 : Math.max(98, cv.text.l),
    },
    primary: invertColorValue(cv.primary, icvLight),
    success: invertColorValue(cv.success, icvLight),
    warning: invertColorValue(cv.warning, icvLight),
    danger: invertColorValue(cv.danger, icvLight),
  };

  if (cv.background.s > 50) {
    // help w contrast by lowering the saturation and lightness if the background is highly saturated
    mult.s = mult.s * 0.75;
    mult.l = mult.l * 0.7;
  }

  return {
    mult,
    colorValues: cv,
    invertedColorValues: icv,
    css: `${mode === 'light' ? ':root,' : ''}
  [data-theme="${mode}"],
  [data-theme="${mode}"] .sl-inverted .sl-inverted,
  [data-theme="${mode}"] .sl-inverted .sl-inverted .sl-inverted .sl-inverted {
    color: var(--color-text);

    ${cssVariablesToStyles(computeCssVariables(cv, { ...mult }))}
}

  ${mode === 'light' ? ':root .sl-inverted,' : ''}
  [data-theme="${mode}"] .sl-inverted,
  [data-theme="${mode}"] .sl-inverted .sl-inverted .sl-inverted {
    color: var(--color-text);

    ${cssVariablesToStyles(
      computeCssVariables(icv, {
        ...mult,
        // lower saturation a bit more in inverted
        s: Math.min(mult.s, 0.9),
      }),
    )}
}`,
  };
};

const invertColorValue = (cv: HslColor2, isBgLight: boolean) => {
  return {
    h: cv.h,
    s: isBgLight ? r(cv.s * 0.8) : cv.s,

    // inverted bg is lighter based on how light regular bg is, w lightness of at least 10
    // l: isBgLight ? r(Math.max(10, 10 * ((cv.l * mult.l) / 100))) : r(100 - cv.l * 0.75),
    // inverted bg is darker if regular db is light, or darker otherwise
    l: isBgLight ? r(Math.max(10, Math.min(20, 100 - cv.l))) : r(100 - cv.l * 0.9),
  };
};

const cssVariablesToStyles = (vars: ReturnType<typeof computeCssVariables>) => {
  let styles = '';
  for (const k in vars) {
    styles += `    --${k}: ${vars[k]};\n`;
  }
  return styles;
};

const computeCssVariables = (cv: ColorValues, mult: MultiplierValues) => {
  const bgLuminance = getLuminance(stringifyHsl(cv.background));
  const textLuminance = getLuminance(stringifyHsl(cv.text));
  const textColor = getContrastColor(bgLuminance, textLuminance, 15, cv.text.h, cv.text.s, cv.text.l);
  cv.text = parseHsl(textColor);

  // if we're rendering light text, then we should also use brightened canvas + borders rather than darkened
  const brightenCanvas = cv.text.l > 50;
  const brightenBorder = brightenCanvas;

  const canvasHsl: {
    pure: HslColor2;
    50: HslColor2;
    100: HslColor2;
    200: HslColor2;
    300: HslColor2;
    400: HslColor2;
    500: HslColor2;
    dialog: HslColor2;
  } = {
    // pure is the darkest or lightest possible canvas color
    pure: {
      h: cv.background.h,
      s: cv.background.s,
      l: brightenCanvas ? Math.max(cv.background.l - 3, 3) : Math.min(cv.background.l + 10, 100),
    },
    50: {
      h: cv.background.h,
      s: r(cv.background.s * 0.8),
      l: r(cv.background.l * 0.97),
    },
    100: {
      h: cv.background.h,
      s: r(cv.background.s * 0.83),
      l: r(cv.background.l * 0.94),
    },
    200: {
      h: cv.background.h,
      s: r(cv.background.s * 0.88),
      l: r(cv.background.l * 0.91),
    },
    300: {
      h: cv.background.h,
      s: r(cv.background.s * 0.91),
      l: r(cv.background.l * 0.88),
    },
    400: {
      h: cv.background.h,
      s: r(cv.background.s * 0.93),
      l: r(cv.background.l * 0.85),
    },
    500: {
      h: cv.background.h,
      s: r(cv.background.s * 0.93),
      l: r(cv.background.l * 0.82),
    },
    dialog: {
      h: cv.background.h,
      s: cv.background.s,
      l: brightenCanvas ? Math.max(cv.background.l - 3, 3) : Math.min(cv.background.l + 10, 100),
    },
  };

  if (brightenCanvas) {
    // the darker the canvas is, the less we need to brighten things
    const brightenMult = 5 / (10 - Math.ceil(bgLuminance * 10));
    canvasHsl[50].l = r(cv.background.l + 40 * brightenMult);
    canvasHsl[100].l = r(cv.background.l + 25 * brightenMult);
    canvasHsl[200].l = r(cv.background.l + 15 * brightenMult);
    canvasHsl[300].l = r(cv.background.l + 8 * brightenMult);
    canvasHsl[400].l = r(cv.background.l + 6 * brightenMult);
    canvasHsl[500].l = r(cv.background.l + 4 * brightenMult);

    // on dark canvas, brighten dialog
    canvasHsl.dialog = canvasHsl[100];
  }

  let shadows = {
    sm: '0px 0px 1px rgba(67, 90, 111, 0.3)',
    md: '0px 2px 4px -2px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(67, 90, 111, 0.3)',
    lg: '0 4px 17px rgba(67, 90, 111, .2), 0 2px 3px rgba(0, 0, 0, .1), inset 0 0 0 .5px var(--color-canvas-pure), 0 0 0 .5px rgba(0, 0, 0, 0.2)',
    xl: '0px 0px 1px rgba(67, 90, 111, 0.3), 0px 8px 10px -4px rgba(67, 90, 111, 0.45)',
    '2xl': '0px 0px 1px rgba(67, 90, 111, 0.3), 0px 16px 24px -8px rgba(67, 90, 111, 0.45)',
  };

  let dropShadows = {
    default1: '0 0 0.5px rgba(0, 0, 0, 0.6)',
    default2: '0 2px 5px rgba(67, 90, 111, 0.3)',
  };

  if (brightenCanvas) {
    // darker shadows on dark canvas
    shadows = {
      sm: '0px 0px 1px rgba(11, 13, 19, 0.5)',
      md: '0px 2px 4px -2px rgba(0, 0, 0, 0.35), 0px 0px 1px rgba(11, 13, 19, 0.4)',
      lg: '0 2px 14px rgba(0,0,0,.55), 0 0 0 0.5px rgba(255,255,255,.2)',
      xl: '0px 0px 1px rgba(11, 13, 19, 0.4), 0px 8px 10px -4px rgba(11, 13, 19, 0.55)',
      '2xl': '0px 0px 1px rgba(11, 13, 19, 0.4), 0px 16px 24px -8px rgba(11, 13, 19, 0.55)',
    };

    dropShadows = {
      default1: '0 0 0.5px rgba(255, 255, 255, .5)',
      default2: '0 3px 8px rgba(0, 0, 0, 0.6)',
    };
  }

  return {
    'text-h': cv.text.h,
    'text-s': `${brightenBorder ? r(cv.text.s * 0.8) : cv.text.s}%`,
    'text-l': `${brightenBorder ? r(cv.text.l * 0.94) : cv.text.l}%`,

    'shadow-sm': shadows.sm,
    'shadow-md': shadows.md,
    'shadow-lg': shadows.lg,
    'shadow-xl': shadows.xl,
    'shadow-2xl': shadows['2xl'],

    'drop-shadow-default1': dropShadows.default1,
    'drop-shadow-default2': dropShadows.default2,

    'color-text-heading': `hsla(var(--text-h), var(--text-s), max(3, calc(var(--text-l) - 15)), 1)`,
    'color-text': `hsla(var(--text-h), var(--text-s), var(--text-l), 1)`,
    'color-text-paragraph': `hsla(var(--text-h), var(--text-s), var(--text-l), 0.9)`,
    'color-text-muted': `hsla(var(--text-h), var(--text-s), var(--text-l), 0.7)`,
    'color-text-light': `hsla(var(--text-h), var(--text-s), var(--text-l), 0.55)`,
    'color-text-disabled': `hsla(var(--text-h), var(--text-s), var(--text-l), 0.3)`,

    'canvas-h': `${cv.background.h}`,
    'canvas-s': `${cv.background.s}%`,
    'canvas-l': `${cv.background.l}%`,
    'color-canvas': `hsla(var(--canvas-h), var(--canvas-s), var(--canvas-l), 1)`,
    'color-canvas-pure': `hsla(${canvasHsl['pure'].h}, ${canvasHsl['pure'].s}%, ${r(canvasHsl['pure'].l)}%, 1)`,
    'color-canvas-tint': `hsla(${canvasHsl[50].h}, ${canvasHsl[50].s}%, ${r(canvasHsl[50].l)}%, ${
      brightenCanvas ? 0.2 : 0.5
    })`,
    'color-canvas-50': `hsla(${canvasHsl[50].h}, ${canvasHsl[50].s}%, ${r(canvasHsl[50].l * mult.l)}%, 1)`,
    'color-canvas-100': `hsla(${canvasHsl[100].h}, ${canvasHsl[100].s}%, ${r(canvasHsl[100].l * mult.l)}%, 1)`,
    'color-canvas-200': `hsla(${canvasHsl[200].h}, ${canvasHsl[200].s}%, ${r(canvasHsl[200].l * mult.l)}%, 1)`,
    'color-canvas-300': `hsla(${canvasHsl[300].h}, ${canvasHsl[300].s}%, ${r(canvasHsl[300].l * mult.l)}%, 1)`,
    'color-canvas-400': `hsla(${canvasHsl[400].h}, ${canvasHsl[400].s}%, ${r(canvasHsl[400].l * mult.l)}%, 1)`,
    'color-canvas-500': `hsla(${canvasHsl[500].h}, ${canvasHsl[500].s}%, ${r(canvasHsl[500].l * mult.l)}%, 1)`,
    'color-canvas-dialog': `hsla(${canvasHsl.dialog.h}, ${canvasHsl.dialog.s}%, ${r(canvasHsl.dialog.l * mult.l)}%, 1)`,

    'color-border-dark': `hsla(var(--canvas-h), ${r(cv.background.s * 0.75)}%, ${
      cv.background.l + (brightenBorder ? 13 : -28)
    }%, 0.5)`,
    'color-border': `hsla(var(--canvas-h), ${r(cv.background.s * 0.8)}%, ${
      cv.background.l + (brightenBorder ? 18 : -22)
    }%, 0.5)`,
    'color-border-light': `hsla(var(--canvas-h), ${r(cv.background.s * 0.6)}%, ${
      cv.background.l + (brightenBorder ? 24 : -16)
    }%, 0.5)`,
    'color-border-input': `hsla(var(--canvas-h), ${r(cv.background.s * 0.6)}%, ${
      cv.background.l + (brightenBorder ? 20 : -28)
    }%, 0.8)`,
    'color-border-button': `hsla(var(--canvas-h), ${r(cv.background.s * 0.6)}%, ${brightenBorder ? 80 : 20}%, 0.65)`,

    'primary-h': `${cv.primary.h}`,
    'primary-s': `${r(cv.primary.s * mult.s)}%`,
    'primary-l': `${r(cv.primary.l * mult.l)}%`,
    'color-text-primary': `hsla(${cv.primary.h}, ${r(cv.primary.s)}%, ${brightenBorder ? 70 : 40}%, 1)`,
    'color-primary-dark': `hsla(${cv.primary.h}, ${r(cv.primary.s * 0.8 * mult.s)}%, ${r(
      cv.primary.l * 0.85 * mult.l,
    )}%, 1)`,
    'color-primary-darker': `hsla(${cv.primary.h}, ${r(cv.primary.s * 0.8 * mult.s)}%, ${r(
      cv.primary.l * 0.65 * mult.l,
    )}%, 1)`,
    'color-primary': `hsla(${cv.primary.h}, ${r(cv.primary.s * mult.s)}%, ${r(cv.primary.l * mult.l)}%, 1)`,
    'color-primary-light': `hsla(${cv.primary.h}, ${r(cv.primary.s * mult.s)}%, ${r(cv.primary.l * 1.2 * mult.l)}%, 1)`,
    'color-primary-tint': `hsla(${cv.primary.h}, ${r(cv.primary.s * mult.s)}%, ${r(65 * mult.l)}%, 0.25)`,
    'color-on-primary': `hsla(${cv.primary.h}, ${cv.primary.s}%, 100%, 1)`,

    'success-h': `${cv.success.h}`,
    'success-s': `${cv.success.s}%`,
    'success-l': `${cv.success.l}%`,
    'color-text-success': `hsla(${cv.success.h}, ${r(cv.success.s * mult.s)}%, ${brightenBorder ? 60 : 40}%, 1)`,
    'color-success-dark': `hsla(${cv.success.h}, ${r(cv.success.s * 0.8 * mult.s)}%, ${r(
      cv.success.l * 0.85 * mult.l,
    )}%, 1)`,
    'color-success-darker': `hsla(${cv.success.h}, ${r(cv.success.s * 0.8 * mult.s)}%, ${r(
      cv.success.l * 0.65 * mult.l,
    )}%, 1)`,
    'color-success': `hsla(${cv.success.h}, ${r(cv.success.s * mult.s)}%, ${r(cv.success.l * mult.l)}%, 1)`,
    'color-success-light': `hsla(${cv.success.h}, ${r(cv.success.s * mult.s)}%, ${r(cv.success.l * 1.2 * mult.l)}%, 1)`,
    'color-success-tint': `hsla(${cv.success.h}, ${r(cv.success.s * mult.s)}%, ${r(65 * mult.l)}%, 0.25)`,
    'color-on-success': `hsla(${cv.success.h}, ${cv.success.s}%, 100%, 1)`,

    'warning-h': `${cv.warning.h}`,
    'warning-s': `${cv.warning.s}%`,
    'warning-l': `${cv.warning.l}%`,
    'color-text-warning': `hsla(${cv.warning.h}, ${r(cv.warning.s * mult.s)}%, ${brightenBorder ? 60 : 40}%, 1)`,
    'color-warning-dark': `hsla(${cv.warning.h}, ${r(cv.warning.s * 0.8 * mult.s)}%, ${r(
      cv.warning.l * 0.85 * mult.l,
    )}%, 1)`,
    'color-warning-darker': `hsla(${cv.warning.h}, ${r(cv.warning.s * 0.8 * mult.s)}%, ${r(
      cv.warning.l * 0.65 * mult.l,
    )}%, 1)`,
    'color-warning': `hsla(${cv.warning.h}, ${r(cv.warning.s * mult.s)}%, ${r(cv.warning.l * mult.l)}%, 1)`,
    'color-warning-light': `hsla(${cv.warning.h}, ${r(cv.warning.s * mult.s)}%, ${r(cv.warning.l * 1.2 * mult.l)}%, 1)`,
    'color-warning-tint': `hsla(${cv.warning.h}, ${r(cv.warning.s * mult.s)}%, ${r(65 * mult.l)}%, 0.25)`,
    'color-on-warning': `hsla(${cv.warning.h}, ${cv.warning.s}%, 100%, 1)`,

    'danger-h': `${cv.danger.h}`,
    'danger-s': `${cv.danger.s}%`,
    'danger-l': `${cv.danger.l}%`,
    'color-text-danger': `hsla(${cv.danger.h}, ${r(cv.danger.s * mult.s)}%, ${brightenBorder ? 60 : 40}%, 1)`,
    'color-danger-dark': `hsla(${cv.danger.h}, ${r(cv.danger.s * 0.8 * mult.s)}%, ${r(
      cv.danger.l * 0.85 * mult.l,
    )}%, 1)`,
    'color-danger-darker': `hsla(${cv.danger.h}, ${r(cv.danger.s * 0.8 * mult.s)}%, ${r(
      cv.danger.l * 0.65 * mult.l,
    )}%, 1)`,
    'color-danger': `hsla(${cv.danger.h}, ${r(cv.danger.s * mult.s)}%, ${r(cv.danger.l * mult.l)}%, 1)`,
    'color-danger-light': `hsla(${cv.danger.h}, ${r(cv.danger.s * mult.s)}%, ${r(cv.danger.l * 1.2 * mult.l)}%, 1)`,
    'color-danger-tint': `hsla(${cv.danger.h}, ${r(cv.danger.s * mult.s)}%, ${r(65 * mult.l)}%, 0.25)`,
    'color-on-danger': `hsla(${cv.danger.h}, ${cv.danger.s}%, 100%, 1)`,
  };
};
