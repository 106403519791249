import { MenuDividerItem, MenuGroup, MenuItemWithSubmenu, MenuOptionGroup, MenuOptionItem } from './types';

export const isMenuGroup = (node: unknown): node is MenuGroup => {
  return node && typeof node === 'object' && node['type'] === 'group';
};

export const isMenuOptionGroup = (node: unknown): node is MenuOptionGroup => {
  return node && typeof node === 'object' && node['type'] === 'option_group';
};

export const isSubmenuItem = (node: unknown): node is MenuItemWithSubmenu => {
  return node && typeof node === 'object' && node['children'] && !node['type'];
};

export const isMenuOption = (node: unknown): node is MenuOptionItem => {
  return node && typeof node === 'object' && node.hasOwnProperty('value') && !node['type'];
};

export const isMenuDivider = (node: unknown): node is MenuDividerItem => {
  return node && typeof node === 'object' && node['type'] === 'divider';
};
