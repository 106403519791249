import cn from 'clsx';
import * as React from 'react';
import { Key } from 'ts-keycode-enum';

import { useControllableState } from '../../hooks/use-controllable-state';
import { Box, PolymorphicComponentProps } from '../Box';
import { Icon, IIconProps } from '../Icon';
import { PanelContent } from './PanelContent';
import { PanelTitlebar } from './PanelTitlebar';
import { AppearanceVals } from './types';

export type PanelOwnProps = {
  /**
   * The contents of the panel.
   *
   * A Panel.Titlebar must be the first child element.
   */
  children: React.ReactNode;

  /**
   * Whether the panel should be rounded. Applies rounded "lg" and overflow hidden.
   *
   * Usually only use this on root panel components, not nested panel components.
   */
  rounded?: boolean;

  /**
   * Controls how the panel will appear.
   *
   * @default 'default'
   */
  appearance?: AppearanceVals;

  /**
   * Enable collapsing
   */
  isCollapsible?: boolean;

  /**
   * Identifier to use for aria controls when collapse is enabled
   */
  id?: string;

  /**
   * Controls whether to show the panel contents or not by default in.
   *
   * If defaultIsOpen is provided the panel will be uncontrolled.
   */
  defaultIsOpen?: boolean;

  /**
   * Controls whether to show the panel contents or not.
   *
   * If no onChange is provided, isOpen will be used as the default value (uncontrolled).
   */
  isOpen?: boolean;

  /**
   * Called when the collapsed state changes.
   *
   * When not provided, the component will control it's own state internally (uncontrolled).
   */
  onChange?: (value: boolean) => void;
};

export type PanelProps = PolymorphicComponentProps<'div', PanelOwnProps>;

export const Panel = ({
  appearance = 'default',
  id,
  className,
  children,
  isCollapsible = true,
  isOpen: isOpenProp,
  defaultIsOpen = false,
  onChange,
  rounded = appearance === 'outlined' ? true : undefined,
  ...extraProps
}: PanelProps) => {
  const [isOpen, setIsOpen] = useControllableState({
    value: isOpenProp,
    defaultValue: defaultIsOpen,
    onChange,
    propsMap: {
      value: 'isOpen',
      defaultValue: 'defaultIsOpen',
      onChange: 'onChange',
    },
  });

  const isMinimal = appearance === 'minimal';
  const isOutlined = appearance === 'outlined';
  const isPanelOpen = !isCollapsible || (isCollapsible && isOpen);

  const handleChange = React.useCallback(() => {
    if (!isCollapsible) return;

    setIsOpen(!isOpen);
  }, [isCollapsible, isOpen, setIsOpen]);

  const handleKeyDown = React.useCallback(
    e => {
      if (!isCollapsible) return;

      if (e.keyCode === Key.Space || e.keyCode === Key.Enter) {
        e.preventDefault();
        handleChange();
      }
    },
    [handleChange, isCollapsible],
  );

  const [titlebarElement, ...contentElements] = React.Children.toArray(children);

  if (
    !React.isValidElement(titlebarElement) ||
    (React.isValidElement(titlebarElement) && titlebarElement.type !== PanelTitlebar)
  ) {
    throw new Error('Panel.Titlebar must be the first child in a Panel');
  }

  let icon: IIconProps['icon'];
  if (isCollapsible) {
    if (isMinimal || isOutlined) {
      icon = isPanelOpen ? 'chevron-down' : 'chevron-right';
    } else {
      icon = ['fas', isPanelOpen ? 'caret-down' : 'caret-right'];
    }
  }

  const titlebarComponent = React.cloneElement(titlebarElement, {
    icon: icon ? <Icon icon={icon} fixedWidth size={isMinimal ? 'xs' : isOutlined ? 'sm' : undefined} /> : undefined,
    role: isCollapsible ? 'button' : undefined,
    'aria-expanded': isCollapsible ? isPanelOpen : undefined,
    'aria-controls': isCollapsible ? id : undefined,
    cursor: isCollapsible ? 'pointer' : undefined,
    tabIndex: isCollapsible ? 0 : undefined,
    appearance,
    onClick: handleChange,
    onKeyDown: handleKeyDown,
  });

  return (
    <Box
      w="full"
      className={cn('sl-panel', 'sl-outline-none', className)}
      overflowX={!isMinimal && rounded ? 'hidden' : undefined}
      overflowY={!isMinimal && rounded ? 'hidden' : undefined}
      rounded={rounded ? 'lg' : undefined}
      border={isOutlined ? true : undefined}
      {...extraProps}
    >
      {titlebarComponent}

      {isPanelOpen ? (
        <Box
          className="sl-panel__content-wrapper"
          bg={isMinimal || isOutlined ? undefined : 'canvas-100'}
          id={isCollapsible ? id : undefined}
          role={isCollapsible ? 'region' : undefined}
          borderT={isOutlined ? true : undefined}
        >
          {contentElements}
        </Box>
      ) : null}
    </Box>
  );
};

Panel.Titlebar = PanelTitlebar;
Panel.Content = PanelContent;
