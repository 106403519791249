import { FocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import { useTab } from '@react-aria/tabs';
import { mergeProps } from '@react-aria/utils';
import { SingleSelectListState } from '@react-stately/list';
import { DOMProps, Node } from '@react-types/shared';
import * as React from 'react';
import { useContext, useRef } from 'react';

import {
  BackgroundColorVals,
  BorderColorVals,
  IBackgroundColorProps,
  IntentVals,
  ITextColorProps,
  SpaceVals,
  TextColorVals,
} from '../../enhancers';
import { Box, BoxOwnProps } from '../Box';
import { Item } from '../Collections';
import { TabsContext } from './TabsContext';
import { DensityVals, variants } from './variants';

export type TabOwnProps = {
  children: React.ReactNode;

  /**
   * An optional unique identifier (within this <Tabs /> component) for the tab - must match up with the id of a corresponding <TabPanel />.
   */
  id?: string;

  isDisabled?: boolean;

  intent?: IntentVals;
};

export const Tab = (props: TabOwnProps) => <Item {...props} />;
Tab.getCollectionNode = Item.getCollectionNode;

interface TabProps<T> extends DOMProps {
  item: Node<T>;
  state: SingleSelectListState<T>;
  isDisabled?: boolean;
  density?: DensityVals;
}

const IntentColorMap: Record<
  IntentVals,
  {
    color: TextColorVals;
    selectedColor: TextColorVals;
    bgTint: BackgroundColorVals;
    selectedBg: BackgroundColorVals;
    selectedBorder: BorderColorVals;
  }
> = {
  default: {
    color: 'muted',
    selectedColor: 'primary-dark',
    bgTint: 'primary-tint',
    selectedBg: 'primary',
    selectedBorder: 'primary',
  },
  success: {
    color: 'success',
    selectedColor: 'success-dark',
    bgTint: 'success-tint',
    selectedBg: 'success',
    selectedBorder: 'success',
  },
  warning: {
    color: 'warning',
    selectedColor: 'warning-dark',
    bgTint: 'warning-tint',
    selectedBg: 'warning',
    selectedBorder: 'warning',
  },
  danger: {
    color: 'danger',
    selectedColor: 'danger-dark',
    bgTint: 'danger-tint',
    selectedBg: 'danger',
    selectedBorder: 'danger',
  },
};

export function TabImpl<T>(props: TabProps<T>) {
  const { item, state, density, isDisabled: propsDisabled } = props;
  const { key, rendered, index } = item;

  const intent: IntentVals = item.props?.intent || 'default';
  const isDisabled = propsDisabled || state.disabledKeys.has(key);

  const tabContext = useContext(TabsContext);
  const { tabsProps } = tabContext;
  const { appearance, orientation } = tabsProps;

  const isPill = appearance === 'pill';
  const isLine = appearance === 'line';

  const disabledKeys = state.disabledKeys;

  // sync `isDisabled` prop with tab list state disabled keys
  React.useEffect(() => {
    if (propsDisabled && !disabledKeys.has(key)) {
      disabledKeys.add(key);
    } else if (!propsDisabled && disabledKeys.has(key)) {
      disabledKeys.delete(key);
    }
  }, [disabledKeys, propsDisabled, key]);

  const ref = useRef<HTMLDivElement>();
  const { tabProps } = useTab({ key, isDisabled }, state, ref);

  const { hoverProps } = useHover({
    ...props,
  });
  const isSelected = state.selectedKey === key;

  const { color: _color, ...propsWithoutColor } = mergeProps(tabProps, hoverProps);

  const stateProps: Partial<BoxOwnProps> = {
    ...variants[appearance][orientation][density].tab,
  };

  if (appearance === 'minimal') {
    if (orientation === 'vertical') {
      // @ts-expect-error
      stateProps.ml = (-1 * stateProps.px) as SpaceVals;

      if (index === 0) {
        // @ts-expect-error
        stateProps.mt = (-1 * stateProps.py) as SpaceVals;
      }
    } else {
      if (index === 0) {
        // @ts-expect-error
        stateProps.ml = (-1 * stateProps.px) as SpaceVals;
      }
    }
  } else {
    if (orientation === 'vertical') {
      // @ts-expect-error
      stateProps.ml = (-1 * stateProps.px) as SpaceVals;
    }
  }

  /**
   * If in loading or disabled states, remove other ui effects like hover
   */
  if (isDisabled) {
    for (const i in stateProps) {
      const prop = stateProps[i];
      if (prop && typeof prop === 'object') {
        // remove props immutably
        const { active, hover, ...newProps } = stateProps[i];
        stateProps[i] = newProps;
      }
    }
  }

  let bg: IBackgroundColorProps['bg'];
  let color: ITextColorProps['color'] = {
    default: isPill ? IntentColorMap[intent].color : 'muted',
    hover: isDisabled ? undefined : 'body',
  };

  if (isSelected) {
    color = isPill ? 'on-primary' : IntentColorMap[intent].selectedColor;
  }

  if (isPill) {
    bg = isSelected
      ? IntentColorMap[intent].selectedBg
      : {
          hover: !isDisabled ? IntentColorMap[intent].bgTint : undefined,
        };
  }

  return (
    <FocusRing focusRingClass="sl-focus-ring">
      <Box
        {...propsWithoutColor}
        ref={ref}
        {...stateProps}
        borderColor={isSelected && isLine ? IntentColorMap[intent].selectedBorder : 'transparent'}
        cursor={isDisabled ? 'not-allowed' : isSelected ? true : 'pointer'}
        fontWeight="medium"
        opacity={isDisabled ? 60 : undefined}
        bg={bg}
        color={color}
      >
        {rendered}
      </Box>
    </FocusRing>
  );
}
