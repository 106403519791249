import { FocusRing } from '@react-aria/focus';
import cn from 'clsx';
import * as React from 'react';
import { memo } from 'react';

import { Box, BoxProps } from '../Box';
import { Heading, HeadingAs, HeadingOwnProps } from '../Heading';
import { Icon } from '../Icon';
import { Link } from '../Link';

export type LinkHeadingOwnProps = HeadingOwnProps & {
  id?: string;
};

export type LinkHeadingProps = LinkHeadingOwnProps & BoxProps<HeadingAs>;

export const LinkHeading = memo(function LinkHeading({ id, children, className, ...props }: LinkHeadingProps) {
  const _className = cn('sl-link-heading', className);

  // Just in case browser doesn't scroll correctly - handle it
  React.useEffect(() => {
    if (document.location.hash === `#${id}`) {
      const timer = setTimeout(() => {
        document.querySelector(`#${id}`).scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 300);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box as={Heading} className={_className} id={id} {...props}>
      <FocusRing focusRingClass="sl-focus-ring" focusClass="sl-focus-ring">
        <Link
          href={`#${id}`}
          className="sl-link-heading__link"
          display="inline-flex"
          alignItems="center"
          color="current"
        >
          <Box>{children}</Box>

          <Box className="sl-link-heading__icon" ml={4} fontSize="base" color="muted">
            <Icon icon="link" />
          </Box>
        </Link>
      </FocusRing>
    </Box>
  );
});
