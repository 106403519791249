import cn from 'clsx';
import memoize from 'nano-memoize';
import equals from 'react-fast-compare';

import { computePseudoClasses, Pseudo } from './pseudo';
import { EnhancerFn } from './types';

export type IntentVals = 'default' | 'success' | 'warning' | 'danger';

export type SemanticColorVals =
  | 'link'
  | 'link-dark'
  | 'code'
  | 'on-code'
  | 'primary'
  | 'primary-tint'
  | 'primary-light'
  | 'primary-dark'
  | 'primary-darker'
  | 'on-primary'
  | 'success'
  | 'success-tint'
  | 'success-light'
  | 'success-dark'
  | 'success-darker'
  | 'on-success'
  | 'warning'
  | 'warning-tint'
  | 'warning-light'
  | 'warning-dark'
  | 'warning-darker'
  | 'on-warning'
  | 'danger'
  | 'danger-tint'
  | 'danger-light'
  | 'danger-dark'
  | 'danger-darker'
  | 'on-danger';

export type TextColorVals =
  | 'current'
  | 'body'
  | 'muted'
  | 'disabled'
  | 'light'
  | 'heading'
  | 'paragraph'
  | 'canvas'
  | 'canvas-pure'
  | 'canvas-tint'
  | 'canvas-50'
  | 'canvas-100'
  | 'canvas-200'
  | 'canvas-300'
  | 'canvas-dialog'
  | SemanticColorVals;

export type PlaceholderColorVals = true | 'primary' | 'success' | 'warning' | 'danger';

export interface ITextColorProps {
  color?: TextColorVals | Pseudo<TextColorVals, 'hover' | 'focus' | 'disabled'>;
}

export interface IPlaceholderProps {
  placeholderColor?: PlaceholderColorVals;
}

export type BackgroundColorVals =
  | 'canvas'
  | 'canvas-pure'
  | 'canvas-tint'
  | 'canvas-50'
  | 'canvas-100'
  | 'canvas-200'
  | 'canvas-300'
  | 'canvas-400'
  | 'canvas-500'
  | 'canvas-dialog'
  | 'transparent'
  | SemanticColorVals;

export interface IBackgroundColorProps {
  bg?: BackgroundColorVals | Pseudo<BackgroundColorVals, 'hover' | 'focus' | 'active' | 'disabled'>;
}

export interface IColorProps extends ITextColorProps, IPlaceholderProps, IBackgroundColorProps {}

export const colorPropNames: Array<keyof IColorProps> = [
  'bg',
  'color',
  // 'placeholderColor' // not a standard box prop, this always goes on the input
];

export const colorProps: EnhancerFn<IColorProps> = (props: IColorProps) => {
  const { color, bg, placeholderColor, ...rest } = props;

  let skipBg = false;
  let skipColor = false;

  // Support hex bg values - not in the typings because adding string to typings clobbers them.. annoying typescript
  if (typeof bg === 'string' && bg.startsWith('#')) {
    // @ts-expect-error
    rest.style = Object.assign({}, rest.style || {}, { backgroundColor: bg });
    skipBg = true;
  }

  // Support hex color values
  if (typeof color === 'string' && color.startsWith('#')) {
    // @ts-expect-error
    rest.style = Object.assign({}, rest.style || {}, { color });
    skipColor = true;
  }

  return {
    props: rest,
    className: _colorProps(skipColor ? undefined : color, skipBg ? undefined : bg, placeholderColor),
  };
};

const _colorProps = memoize(
  (color: IColorProps['color'], bg: IColorProps['bg'], placeholderColor: IColorProps['placeholderColor']) => {
    return cn(
      computePseudoClasses('bg', bg),
      computePseudoClasses('text', color),
      computePseudoClasses('placeholder', placeholderColor),
    );
  },
  { maxAge: Infinity, equals },
);
