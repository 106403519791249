import { getLuminance, mix, parseToHsl, parseToRgb, rgba } from 'polished';

export type HslColor2 = { h: number; s: number; l: number; a?: number };

export const getContrastColor = (
  bgLuminance: number,
  fgLuminance: number,
  targetContrast: number,
  hue: number,
  saturation: number = 1,
  lightness: number = 50,
) => {
  let targetLuminance: number;
  if (fgLuminance < bgLuminance) {
    targetLuminance = (bgLuminance + 0.05) / targetContrast - 0.05;
  } else {
    targetLuminance = targetContrast * (bgLuminance + 0.05) - 0.05;
  }

  return setLuminance(targetLuminance, stringifyHsl({ h: hue, s: saturation, l: lightness }));
};

export const parseHsl = (hsl: string): HslColor2 => {
  const hslRegexp = /hsl\((\d+),\s*([\d.]+)%,\s*([\d.]+)%\)/g;
  const hslaRegexp = /hsla\((\d+),\s*([\d.]+)%,\s*([\d.]+)%,\s*([\d.]+)\)/g;
  let parts = hslRegexp.exec(hsl);
  if (!parts) {
    parts = hslaRegexp.exec(hsl);
  }

  if (!parts) {
    try {
      const hslParts = parseToHsl(hsl);
      return {
        h: Math.round(hslParts.hue),
        s: parseFloat((hslParts.saturation * 100).toFixed(0)),
        l: Math.max(1, Math.min(99, Math.round(hslParts.lightness * 100))),
        // @ts-expect-error
        a: hslParts.alpha !== void 0 ? hslParts.alpha : 1,
      };
    } catch (e) {
      console.warn(e);
      return { h: 0, s: 0, l: 100, a: 1 };
    }
  }

  return { h: parseInt(parts[1]), s: parseFloat(parts[2]), l: parseFloat(parts[3]) };
};

export const stringifyHsl = (hsl: HslColor2): string => {
  if (hsl.a !== void 0) {
    return `hsla(${hsl.h}, ${hsl.s}%, ${hsl.l}%, ${hsl.a})`;
  } else {
    return `hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)`;
  }
};

const EPS = 1e-7;
const setLuminance = (amount, color): string => {
  // @ts-expect-error
  const { alpha = 1 } = parseToRgb(color);

  let rgb;
  if (amount === 0) {
    rgb = rgba(0, 0, 0, alpha);
  } else if (amount === 1) {
    rgb = rgba(255, 255, 255, alpha);
  } else {
    let maxIteration = 20;
    const test = (color1, color2) => {
      const mixed = mix(0.5, color1, color2);
      const mixedLuminance = getLuminance(mixed);
      if (Math.abs(amount - mixedLuminance) < EPS || !maxIteration--) {
        return mixed;
      }

      if (mixedLuminance > amount) {
        return test(color1, mixed);
      }

      return test(mixed, color2);
    };

    rgb = getLuminance(color) > amount ? test('#000', color) : test(color, '#fff');
  }

  return rgb;
};
