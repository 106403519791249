import cn from 'clsx';
import * as React from 'react';
import { forwardRef } from 'react';

import { IntentVals } from '../../enhancers';
import { splitBoxProps } from '../../utils';
import { Box } from '../Box';
import { BoxOwnProps, PolymorphicComponentProps } from '../Box/types';
import { Flex } from '../Flex';
import { Icon, IIconProps, isIconProp } from '../Icon';
import { AppearanceVals, sizes, variants } from './variants';

export type BadgeOwnProps = {
  /** Name of a Font Awesome Icon to render with badge*/
  icon?: IIconProps['icon'] | React.ReactElement;

  iconRight?: IIconProps['icon'] | React.ReactElement;

  /**
   * Whether this badge should use large styles.
   *
   * @default false
   */
  size?: 'sm' | 'md' | 'lg';

  /**
   * Badge apperence style.
   *
   * @default default
   */
  appearance?: AppearanceVals;

  /**
   * Intent style for the badge. Setting a color scheme will override this.
   * @default false
   */
  intent?: IntentVals;

  /**
   * Appends a remove icon to right right of badge and exectues this callback function on click
   * @default false
   */
  onRemove?: (e: any) => void;
};

const defaultElement = 'span';

export type BadgeProps<E extends React.ElementType = typeof defaultElement> = PolymorphicComponentProps<
  E,
  BadgeOwnProps
>;

export const Badge = React.memo(
  forwardRef<HTMLElement, BadgeProps>(function Input(
    {
      appearance = 'solid',
      size = 'md',
      as = defaultElement,
      className,
      intent,
      icon,
      iconRight,
      onRemove,
      children,
      ...props
    },
    ref,
  ) {
    const { remainingProps } = splitBoxProps(props);

    const stateProps: Partial<BoxOwnProps> = {
      ...variants[appearance].default,
      ...variants[appearance][intent],
    };

    return (
      <Box
        ref={ref}
        as={as}
        h={sizes[size].h}
        px={sizes[size].px}
        py={sizes[size].py}
        fontSize={sizes[size].fontSize}
        rounded={sizes[size].rounded}
        borderColor="on-primary"
        className={cn('sl-badge', className, { 'sl-badge--remove': !!onRemove })}
        {...stateProps}
        {...remainingProps}
      >
        <Flex justifyItems="start" alignItems="center">
          {icon ? <BadgeIcon icon={icon} size={size} hasContent={!!children} /> : null}

          <Flex>{children}</Flex>

          {iconRight && !onRemove ? <BadgeRightIcon icon={iconRight} size={size} /> : null}
          {onRemove ? (
            <Box as={'a'} onClick={onRemove} ml={0.5}>
              <BadgeRightIcon icon={['fas', 'times-circle']} size={size} />
            </Box>
          ) : null}
        </Flex>
      </Box>
    );
  }),
);

const BadgeIcon = ({
  icon,
  size,
  hasContent,
  pulse,
}: Pick<BadgeOwnProps, 'icon' | 'size'> & {
  pulse?: boolean;
  hasContent: Boolean;
}) => {
  let elem = icon;
  if (isIconProp(icon)) {
    elem = <Icon icon={icon} style={{ fontSize: sizes[size].iconSize }} pulse={pulse} fixedWidth />;
  }

  return (
    <Box
      data-testid="icon"
      mr={hasContent ? sizes[size].leftIconMr : undefined}
      mx={hasContent ? undefined : sizes[size].leftIconMx}
    >
      {elem}
    </Box>
  );
};

const BadgeRightIcon = ({ icon, size }: Pick<BadgeOwnProps, 'icon' | 'size'>) => {
  let elem = icon;
  if (isIconProp(icon)) {
    elem = (
      <Icon
        icon={icon}
        style={{
          fontSize: sizes[size].iconSize,
        }}
        fixedWidth
      />
    );
  }

  return (
    <Box data-testid="iconright" ml={sizes[size].rightIconMl} mr={sizes[size].rightIconMr}>
      {elem}
    </Box>
  );
};
