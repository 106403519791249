import { SizeProp } from '@fortawesome/fontawesome-svg-core';

import { FontSizeVals, HeightVals, IntentVals, NegativeSpaceVals, RoundedVals, SpaceVals } from '../../enhancers';
import { BoxOwnProps } from '../Box/types';

export type AppearanceVals = 'solid' | 'outline' | 'minimal';

export const sizes: Partial<
  Record<
    HeightVals,
    {
      h: HeightVals;
      px: SpaceVals;
      py: SpaceVals;
      fontSize: FontSizeVals;
      rounded: RoundedVals;
      leftIconMx: SpaceVals | NegativeSpaceVals;
      leftIconMr: SpaceVals | NegativeSpaceVals;
      rightIconMl: SpaceVals | NegativeSpaceVals;
      rightIconMr: SpaceVals | NegativeSpaceVals;
      iconSize: string;
    }
  >
> = {
  lg: {
    h: 'md',
    px: 3,
    py: 0.5,
    fontSize: 'lg',
    rounded: 'full',
    leftIconMx: -2,
    leftIconMr: 2,
    rightIconMl: 1,
    rightIconMr: -0.5,
    iconSize: '15px',
  },
  md: {
    h: 'sm',
    px: 2,
    py: 0.5,
    fontSize: 'base',
    rounded: 'full',
    leftIconMx: -1,
    leftIconMr: 1.5,
    rightIconMl: 0.5,
    rightIconMr: -0.5,
    iconSize: '13px',
  },
  sm: {
    h: 'xs',
    px: 1.5,
    py: 0.5,
    fontSize: 'sm',
    rounded: 'full',
    leftIconMx: -1,
    leftIconMr: 1,
    rightIconMl: 0.5,
    rightIconMr: -0.5,
    iconSize: '13px',
  },
};

export const variants: Record<AppearanceVals, Record<IntentVals, Partial<BoxOwnProps>>> = {
  solid: {
    default: {
      border: 0,
      color: 'paragraph',
      fontSize: 'paragraph-tiny',
      bg: {
        default: 'canvas-100',
      },
      borderColor: {
        default: 'input',
      },
    },
    success: {
      color: 'on-success',
      borderColor: 'success',
      bg: {
        default: 'success',
      },
    },
    warning: {
      color: 'on-warning',
      borderColor: 'warning',
      bg: {
        default: 'warning',
      },
    },
    danger: {
      color: 'on-danger',
      borderColor: 'danger',
      bg: {
        default: 'danger',
      },
    },
  },
  minimal: {
    default: {
      color: 'paragraph',
      fontSize: 'paragraph-tiny',
      bg: {
        default: 'transparent',
      },
      borderColor: 'transparent',
    },
    success: {
      color: 'success',
    },
    warning: {
      color: 'warning',
    },
    danger: {
      color: 'danger',
    },
  },
  outline: {
    default: {
      color: 'paragraph',
      fontSize: 'paragraph-tiny',
      bg: {
        default: 'canvas',
      },
      borderColor: {
        default: 'input',
      },
      ring: false,
    },
    success: {
      borderColor: 'success',
      color: 'success',
    },
    warning: {
      borderColor: 'warning',
      color: 'warning',
    },
    danger: {
      borderColor: 'danger',
      color: 'danger',
    },
  },
};
