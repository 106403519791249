import {
  findIconDefinition,
  IconDefinition,
  IconLookup,
  IconPrefix,
  IconProp,
  library,
} from '@fortawesome/fontawesome-svg-core';
import {
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCopy,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandArrowsAlt,
  faExternalLinkAlt,
  faInfoCircle,
  faLink,
  faSort,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import cn from 'clsx';
import * as React from 'react';
import { memo } from 'react';
import create from 'zustand';

export interface IIconProps extends FontAwesomeIconProps {}

type State = {
  defaultStyle: 'fas' | 'far' | 'fal' | 'fad';
  setDefaultStyle: (style: State['defaultStyle']) => void;
};

export const useIconStore = create<State>(set => ({
  defaultStyle: 'fas',
  setDefaultStyle: style => set(state => ({ ...state, defaultStyle: style })),
}));

const _className = 'sl-icon';

let addedToLibrary = false;
const initLibrary = () => {
  library.add(
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCaretUp,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCopy,
    faExclamationCircle,
    faExclamationTriangle,
    faExpandArrowsAlt,
    faExternalLinkAlt,
    faInfoCircle,
    faLink,
    faSort,
  );
  addedToLibrary = true;
};

export const Icon = memo<IIconProps>(function Icon({ className, icon, ...props }) {
  const style = useIconStore(state => state.defaultStyle);
  const iconProp = normalizeIconArgs(icon, style);

  /**
   * So that if Icon is imported, the library stuff is not tree-shaken out. If `library.add` was hoisted out of this
   * function then it would be shaken out at consumer build time.
   */
  if (!addedToLibrary) initLibrary();

  if (isIconDefinition(iconProp) || findIconDefinition(iconProp)) {
    return <FontAwesomeIcon className={cn(_className, className)} icon={iconProp} {...props} />;
  }

  // if the icon is not bundled with the mosaic core (via library in Icon/index.ts), then render fallback <i> in
  // case font awesome kit or css is loaded on page
  return (
    <i
      role="img"
      aria-hidden="true"
      className={cn(_className, className, iconProp.prefix, `fa-${iconProp.iconName}`, {
        'fa-spin': props.spin,
        'fa-pulse': props.pulse,
        'fa-fw': props.fixedWidth,
        [`fa-${props.size}`]: props.size,
      })}
      style={props.style}
    />
  );
});

export function isIconDefinition(prop?: unknown): prop is IconDefinition {
  if (prop && typeof prop === 'object' && prop.hasOwnProperty('icon')) return true;
  return false;
}

export function isIconProp(prop?: unknown): prop is IconProp {
  if ((prop && typeof prop === 'string') || Array.isArray(prop)) return true;
  if (prop && typeof prop === 'object' && prop.hasOwnProperty('iconName')) return true;
  return false;
}

// Adapted from https://github.com/FortAwesome/react-fontawesome/blob/master/src/utils/normalize-icon-args.js
// Adds defaultPrefix and adjusts to fix some typings issues
function normalizeIconArgs(icon: IconProp, defaultPrefix: IconPrefix = 'fas'): IconLookup | IconDefinition {
  // if the icon is null, there's nothing to do
  if (icon === null) {
    return null;
  }

  if (Array.isArray(icon)) {
    // use the first item as prefix, second as icon name
    return { prefix: icon[0], iconName: icon[1] };
  }

  // if the icon is an object and has a prefix and an icon name, return it
  if (typeof icon === 'object' && icon.iconName) {
    return icon;
  }

  // if it's a string, use it as the icon name
  if (typeof icon === 'string') {
    return { prefix: defaultPrefix, iconName: icon };
  }
}
