import * as React from 'react';
import { forwardRef, memo } from 'react';

import { ParagraphSizes } from '../../enhancers';
import { Box, PolymorphicComponentProps } from '../Box';

export type ParagraphSizeVals = 'leading' | 'default' | 'small' | 'tiny';

export type ParagraphOwnProps = {
  size?: ParagraphSizeVals;
};

export type ParagraphProps<E extends React.ElementType = typeof defaultElement> = PolymorphicComponentProps<
  E,
  ParagraphOwnProps
>;

const defaultElement = 'p';
const sizes: Record<ParagraphSizeVals, { fontSize: ParagraphSizes; lineHeight: ParagraphSizes }> = {
  leading: {
    fontSize: 'paragraph-leading',
    lineHeight: 'paragraph-leading',
  },
  default: {
    fontSize: 'paragraph',
    lineHeight: 'paragraph',
  },
  small: {
    fontSize: 'paragraph-small',
    lineHeight: 'paragraph-small',
  },
  tiny: {
    fontSize: 'paragraph-tiny',
    lineHeight: 'paragraph-tiny',
  },
};

export const Paragraph: <E extends React.ElementType = typeof defaultElement>(props: ParagraphProps<E>) => JSX.Element =
  memo(
    forwardRef(
      <E extends React.ElementType>(
        { size = 'default', ...restProps }: ParagraphProps<E>,
        ref: typeof restProps.ref,
      ) => {
        /**
         * Explicitely do not allow end user to override font and color... Paragraph should only be used for prose, not UI.
         */
        return (
          <Box as={defaultElement} ref={ref} {...sizes[size]} {...restProps} fontFamily="prose" color="paragraph" />
        );
      },
    ),
  );
